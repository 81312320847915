import React, { useContext, useEffect } from "react";
import { TechnologiesMainData } from "../../../CommanComponents/Technologies/Technologies.js";
import { AIDataTech, BlockchainDataTech, FrontendDataTech } from "./data.jsx";
import { BackendDataTech } from "./data.jsx";

import { QADataTech } from "../../../CommanComponents/Technologies/Technologies.js";

import { DevopsDataTech } from "../../../CommanComponents/Technologies/Technologies.js";

import { ManagementTech } from "../../../CommanComponents/Technologies/Technologies.js";
import { UIUXDataTech } from "./data.jsx";
import { MobileTech } from "./data.jsx";
import MyContext from "../../Context/MyContext.js";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import { useMediaQuery } from "react-responsive";
import Slider from "react-slick";
import Button from "@/app/components/ui/common/Button/page.jsx";

const ProjectTechnology = () => {
    const isMobile = useMediaQuery({ query: `(max-width: 768px)` });

    const {
        technologies,
        setTechnologies,
        technologiesChildData,
        setTechnologiesChildData,
        modifiedHourlyData,
        setModifiedHourlyData,
        modifiedMonthlyData,
        setModifiedMonthlyData,
    } = useContext(MyContext);

    const backgroundStyle = {
        backgroundImage:
            "linear-gradient(98.46deg, #410EAD -1.13%, #7B3FE4 98.96%)",
    };

    useEffect(() => {
        if (technologies === "Frontend") {
            setTechnologiesChildData("ReactJS");
            setModifiedHourlyData([
                {
                    id: 1,
                    title: "For 40 Hours",
                    hour: "from $12/ hr",
                    days: "*Validity 30 days",
                },
                {
                    id: 2,
                    title: "For 80 Hours",
                    hour: "from $11/ hr",
                    days: "*Validity 90 days",
                },
                {
                    id: 3,
                    title: "For 120 Hours",
                    hour: "from $10/ hr",
                    days: "*Validity 120 days",
                },
            ]);
            setModifiedMonthlyData([
                {
                    id: 1,
                    title: "Junior Dev",
                    hour: "from $1800/ mo",
                    experience: "1-2 years Experienced",
                },
                {
                    id: 2,
                    title: "Mid Dev",
                    hour: "from $2000/ mo",
                    experience: "2-3 years Experienced",
                },
                {
                    id: 3,
                    title: "Senior Dev",
                    hour: "from $2200/ mo",
                    experience: "3-5 years Experienced",
                },
                {
                    id: 4,
                    title: "Associate Technical Head",
                    hour: "from $2500/ mo",
                    experience: "5-7 years Experienced",
                },
                {
                    id: 5,
                    title: "Technical Head",
                    hour: "from $3000/ mo",
                    experience: "8+ years Experienced",
                },
            ]);
        } else if (technologies === "Backend") {
            setTechnologiesChildData("Laravel");
            setModifiedHourlyData([
                {
                    id: 1,
                    title: "For 40 Hours",
                    hour: "from $14/ hr",
                    days: "*Validity 30 days",
                },
                {
                    id: 2,
                    title: "For 80 Hours",
                    hour: "from $13/ hr",
                    days: "*Validity 90 days",
                },
                {
                    id: 3,
                    title: "For 120 Hours",
                    hour: "from $12/ hr",
                    days: "*Validity 120 days",
                },
            ]);
            setModifiedMonthlyData([
                {
                    id: 1,
                    title: "Junior Dev",
                    hour: "from $2000/ mo",
                    experience: "1-2 years Experienced",
                },
                {
                    id: 2,
                    title: "Mid Dev",
                    hour: "from $2200/ mo",
                    experience: "2-3 years Experienced",
                },
                {
                    id: 3,
                    title: "Senior Dev",
                    hour: "from $2500/ mo",
                    experience: "3-5 years Experienced",
                },
                {
                    id: 4,
                    title: "Associate Technical Head",
                    hour: "from $2800/ mo",
                    experience: "5-7 years Experienced",
                },
                {
                    id: 5,
                    title: "Technical Head",
                    hour: "from $3500/ mo",
                    experience: "8+ years Experienced",
                },
            ]);
        } else if (technologies === "Blockchain") {
            setTechnologiesChildData("Solidity");
            setModifiedHourlyData([
                {
                    id: 1,
                    title: "For 40 Hours",
                    hour: "from $28/ hr",
                    days: "*Validity 30 days",
                },
                {
                    id: 2,
                    title: "For 80 Hours",
                    hour: "from $25/ hr",
                    days: "*Validity 90 days",
                },
                {
                    id: 3,
                    title: "For 120 Hours",
                    hour: "from $22/ hr",
                    days: "*Validity 120 days",
                },
            ]);
            setModifiedMonthlyData([
                {
                    id: 1,
                    title: "Junior Dev",
                    hour: "from $2000/ mo",
                    experience: "1-2 years Experienced",
                },
                {
                    id: 2,
                    title: "Mid Dev",
                    hour: "from $2500/ mo",
                    experience: "2-3 years Experienced",
                },
                {
                    id: 3,
                    title: "Senior Dev",
                    hour: "from $2800/ mo",
                    experience: "3-5 years Experienced",
                },
                {
                    id: 4,
                    title: "Associate Technical Head",
                    hour: "from $3500/ mo",
                    experience: "5-7 years Experienced",
                },
                {
                    id: 5,
                    title: "Technical Head",
                    hour: "from $3800/ mo",
                    experience: "8+ years Experienced",
                },
            ]);
        } else if (technologies === "Mobile") {
            setTechnologiesChildData("Flutter (Dart)");
            setModifiedMonthlyData([
                {
                    id: 1,
                    title: "Junior Dev",
                    hour: "from $1800/ mo",
                    experience: "1-2 years Experienced",
                },
                {
                    id: 2,
                    title: "Mid Dev",
                    hour: "from $2000/ mo",
                    experience: "2-3 years Experienced",
                },
                {
                    id: 3,
                    title: "Senior Dev",
                    hour: "from $2200/ mo",
                    experience: "3-5 years Experienced",
                },
                {
                    id: 4,
                    title: "Associate Technical Head",
                    hour: "from $2500/ mo",
                    experience: "5-7 years Experienced",
                },
                {
                    id: 5,
                    title: "Technical Head",
                    hour: "from $3000/ mo",
                    experience: "8+ years Experienced",
                },
            ]);
            setModifiedHourlyData([
                {
                    id: 1,
                    title: "For 40 Hours",
                    hour: "from $12/ hr",
                    days: "*Validity 30 days",
                },
                {
                    id: 2,
                    title: "For 80 Hours",
                    hour: "from $11/ hr",
                    days: "*Validity 90 days",
                },
                {
                    id: 3,
                    title: "For 120 Hours",
                    hour: "from $10/ hr",
                    days: "*Validity 120 days",
                },
            ]);
        } else if (technologies === "UIUX") {
            setTechnologiesChildData("Adobe Photoshop");
            setModifiedMonthlyData([
                {
                    id: 1,
                    title: "Junior Dev",
                    hour: "from $1200/ mo",
                    experience: "1-2 years Experienced",
                },
                {
                    id: 2,
                    title: "Mid Dev",
                    hour: "from $1500/ mo",
                    experience: "2-3 years Experienced",
                },
                {
                    id: 3,
                    title: "Senior Dev",
                    hour: "from $1800/ mo",
                    experience: "3-5 years Experienced",
                },
                {
                    id: 4,
                    title: "Associate Technical Head",
                    hour: "from $2000/ mo",
                    experience: "5-7 years Experienced",
                },
                {
                    id: 5,
                    title: "Technical Head",
                    hour: "from $2500/ mo",
                    experience: "8+ years Experienced",
                },
            ]);
            setModifiedHourlyData([
                {
                    id: 1,
                    title: "For 40 Hours",
                    hour: "from $11/ hr",
                    days: "*Validity 30 days",
                },
                {
                    id: 2,
                    title: "For 80 Hours",
                    hour: "from $10/ hr",
                    days: "*Validity 90 days",
                },
                {
                    id: 3,
                    title: "For 120 Hours",
                    hour: "from $8/ hr",
                    days: "*Validity 120 days",
                },
            ]);
        } else if (technologies === "AI") {
            setTechnologiesChildData("Machine Learning");
            setModifiedMonthlyData([
                {
                    id: 1,
                    title: "Junior Dev",
                    hour: "from $2000/ mo",
                    experience: "1-2 years Experienced",
                },
                {
                    id: 2,
                    title: "Mid Dev",
                    hour: "from $2500/ mo",
                    experience: "2-3 years Experienced",
                },
                {
                    id: 3,
                    title: "Senior Dev",
                    hour: "from $2800/ mo",
                    experience: "3-5 years Experienced",
                },
                {
                    id: 4,
                    title: "Associate Technical Head",
                    hour: "from $3500/ mo",
                    experience: "5-7 years Experienced",
                },
                {
                    id: 5,
                    title: "Technical Head",
                    hour: "from $3800/ mo",
                    experience: "8+ years Experienced",
                },
            ]);
            setModifiedHourlyData([
                {
                    id: 1,
                    title: "For 40 Hours",
                    hour: "from $28/ hr",
                    days: "*Validity 30 days",
                },
                {
                    id: 2,
                    title: "For 80 Hours",
                    hour: "from $25/ hr",
                    days: "*Validity 90 days",
                },
                {
                    id: 3,
                    title: "For 120 Hours",
                    hour: "from $22/ hr",
                    days: "*Validity 120 days",
                },
            ]);
        } else {
            setTechnologiesChildData("ReactJS");
            setModifiedMonthlyData([
                {
                    id: 1,
                    title: "Junior Dev",
                    hour: "from $1850/ mo",
                    experience: "1-2 years Experienced",
                },
                {
                    id: 2,
                    title: "Mid Dev",
                    hour: "from $2250/ mo",
                    experience: "2-3 years Experienced",
                },
                {
                    id: 3,
                    title: "Senior Dev",
                    hour: "from $2650/ mo",
                    experience: "3-5 years Experienced",
                },
                {
                    id: 4,
                    title: "Associate Technical Head",
                    hour: "from $3650/ mo",
                    experience: "5-7 years Experienced",
                },
                {
                    id: 5,
                    title: "Technical Head",
                    hour: "from $6750/ mo",
                    experience: "8+ years Experienced",
                },
            ]);
            setModifiedHourlyData([
                {
                    id: 1,
                    title: "For 40 Hours",
                    hour: "from $40/ hr",
                    days: "*Validity 30 days",
                },
                {
                    id: 2,
                    title: "For 80 Hours",
                    hour: "from $37/ hr",
                    days: "*Validity 90 days",
                },
                {
                    id: 3,
                    title: "For 120 Hours",
                    hour: "from $37/ hr",
                    days: "*Validity 120 days",
                },
            ]);
        }
    }, [technologies]);
    const dataArrays = {
        Frontend: FrontendDataTech,
        Backend: BackendDataTech,
        Blockchain: BlockchainDataTech,
        AI: AIDataTech,
        Mobile: MobileTech,
        UIUX: UIUXDataTech,
    };
    const selectedArray = dataArrays[technologies];

    const settings = {
        dots: false,
        infinite: false,
        speed: 300,
        slidesToShow: 4,
        slidesToScroll: 2,
        variableWidth: true,
        // adaptiveHeight: true,
        // centerMode: true,
        // centerPadding: "0px",
        // slide: "container",
    };

    return (
        <>
            <div
                className={`${
                    !isMobile && "NFTFractures-border1"
                } lg:col-span-4 md:px-5`}
            >
                <div
                    className=" w-full h-auto rounded-xl flex flex-col items-center justify-center p-8 mt-5 md:mt-0"
                    style={backgroundStyle}
                >
                    <div className="text-white text-center text-xl font-bold">
                        Want to start a Project?
                    </div>
                    <Button className="mt-5 border-2 border-white rounded-md text-white px-4 py-2">
                        Get a Free Quote
                    </Button>
                </div>

                <div
                    className="w-full h-auto rounded-xl border-purple-700 border-2 mt-5 py-2 pl-5 overflow-x-scroll whitespace-nowrap scrollbar"
                    id="tech-scroll-container"
                >
                    <div className="flex">
                        {TechnologiesMainData.map((tech) => (
                            <div key={tech.id}>
                                <span
                                    className={`inline-block hover:bg-[#410EAD] text-[#410EAD] hover:text-white border hover:border-[#410EAD] ease-in-out duration-700 shadow shadow-[#00000040] font-semibold text-sm px-5 py-3 rounded-full mr-3 my-2 font-manrope cursor-pointer ${
                                        technologies === tech?.title
                                            ? "!bg-[#410EAD] text-white"
                                            : "bg-white"
                                    }`}
                                    onClick={(e) => {
                                        setTechnologies(tech?.title);

                                        // Scroll the clicked element into view
                                        e.target.scrollIntoView({
                                            behavior: "smooth",
                                            block: "nearest",
                                            inline: "center",
                                        });
                                    }}
                                >
                                    {tech.title}
                                </span>
                            </div>
                        ))}
                    </div>
                </div>

                {/* <Swiper
                    slidesPerView={"auto"}
                    spaceBetween={10}
                    freeMode={true}
                    className="w-full h-auto rounded-xl border-purple-700 border-2 mt-5 py-2 pl-5 overflow-x-scroll whitespace-nowrap scrollbar"
                >
                    {TechnologiesMainData.map((tech) => (
                        <SwiperSlide key={tech.id} style={{ width: "auto" }}>
                            <button
                                className={`inline-block  hover:bg-[#410EAD] text-[#410EAD] hover:text-white border hover:border-[#410EAD] ease-in-out duration-700 shadow shadow-[#00000040] font-semibold text-sm px-5 py-3 rounded-full mr-3 my-2 font-manrope ${
                                    technologies === tech?.title
                                        ? "!bg-[#410EAD] text-white"
                                        : "bg-white"
                                }`}
                                onClick={() => {
                                    setTechnologies(tech?.title);
                                }}
                            >
                                {tech.title}
                            </button>
                        </SwiperSlide>
                    ))}
                </Swiper> */}

                <div className="mt-3.5 md:mx-1 mx-3">
                    <div className="flex gap-3 flex-wrap">
                        <div className="">
                            <div className="flex gap-3  flex-wrap">
                                {selectedArray
                                    ?.filter(
                                        (data) => data.id >= 0 && data.id <= 17
                                    )
                                    .map((data) => (
                                        <div
                                            key={data.id}
                                            className={`bg-[#F8F4FF] w-auto  p-2 text-sm rounded-xl cursor-pointer hover:bg-[#410EAD]/30 ${
                                                technologiesChildData ===
                                                data?.title
                                                    ? "!bg-[#410EAD] text-white font-semibold"
                                                    : ""
                                            }`}
                                            onClick={() => {
                                                setTechnologiesChildData(
                                                    data?.title
                                                );
                                                setModifiedHourlyData(
                                                    data?.hourlyData
                                                );
                                                setModifiedMonthlyData(
                                                    data?.monthlyData
                                                );
                                            }}
                                        >
                                            {data.title}
                                        </div>
                                    ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ProjectTechnology;
