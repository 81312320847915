import(/* webpackMode: "eager" */ "/vercel/path0/app/(pages)/Home/ClientSlider/ClientSlider.jsx");
import(/* webpackMode: "eager" */ "/vercel/path0/app/(pages)/Home/DigitalProductDevelopment/DigitalProductDevelopment.jsx");
import(/* webpackMode: "eager" */ "/vercel/path0/app/(pages)/Home/Enterprise/Enterprise.jsx");
import(/* webpackMode: "eager" */ "/vercel/path0/app/(pages)/Home/HomeTitle/HomeMain.jsx");
import(/* webpackMode: "eager" */ "/vercel/path0/app/(pages)/Home/Project/Project.jsx");
import(/* webpackMode: "eager" */ "/vercel/path0/app/(pages)/Home/Services/Services.jsx");
import(/* webpackMode: "eager" */ "/vercel/path0/app/CommanComponents/ContactForm/ContactForm.jsx");
import(/* webpackMode: "eager" */ "/vercel/path0/app/CommanComponents/ContactWithMitting/Page.js");
import(/* webpackMode: "eager" */ "/vercel/path0/app/CommanComponents/EducationSector/EducationSector.jsx");
import(/* webpackMode: "eager" */ "/vercel/path0/app/CommanComponents/OurRecentWork/page.js");
import(/* webpackMode: "eager" */ "/vercel/path0/app/CommanComponents/Technologies/page.jsx");
import(/* webpackMode: "eager" */ "/vercel/path0/app/components/ui/common/BeforeYouLeave.jsx");
import(/* webpackMode: "eager" */ "/vercel/path0/app/components/ui/common/Button/page.jsx");
import(/* webpackMode: "eager" */ "/vercel/path0/app/components/ui/common/FlyingPlan/FlyingPlane.css");
import(/* webpackMode: "eager" */ "/vercel/path0/app/components/ui/common/HomeFaqs.jsx");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/font-awesome/css/font-awesome.min.css");
import(/* webpackMode: "eager" */ "/vercel/path0/app/(pages)/Home/Marquees/Marquees.css");
import(/* webpackMode: "eager" */ "/vercel/path0/app/(pages)/Home/Innovation/Innovation.css");
import(/* webpackMode: "eager" */ "/vercel/path0/app/(pages)/Home/OurComprehnsive/OurComprehnsive.css");
import(/* webpackMode: "eager" */ "/vercel/path0/app/(pages)/Home/IndustryDemand/Industry.css");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/react-fast-marquee/dist/index.js");
import(/* webpackMode: "eager" */ "/vercel/path0/public/Img/FlyingPlane/box.png");
import(/* webpackMode: "eager" */ "/vercel/path0/public/Img/FlyingPlane/fan.png");
import(/* webpackMode: "eager" */ "/vercel/path0/public/Img/FlyingPlane/plain.png");
import(/* webpackMode: "eager" */ "/vercel/path0/public/Img/Home/Chainlink.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/public/Img/Home/Chainpass.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/public/Img/Home/cio-review.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/public/Img/Home/Circle.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/public/Img/Home/ClientReviews1.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/public/Img/Home/ClientReviews2.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/public/Img/Home/clutch.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/public/Img/Home/cmmi.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/public/Img/Home/definiteProccess.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/public/Img/Home/DraftKings.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/public/Img/Home/ElementFI.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/public/Img/Home/Emc2.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/public/Img/Home/ethereum-icon.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/public/Img/Home/Gamestop.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/public/Img/Home/hand-drawn-nft-style-ape-illustration.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/public/Img/Home/Illuvium.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/public/Img/Home/Ledger.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/public/Img/Home/maskGroup.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/public/Img/Home/maskGroup1.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/public/Img/Home/Polygon.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/public/Img/Home/Rainbow.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/public/Img/Home/ReactLogo.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/public/Img/Home/right-arrow.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/public/Img/Home/Royal.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/public/Img/Home/sdk_1.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/public/Img/Home/sdk_2.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/public/Img/Home/sdk_3.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/public/Img/Home/sdk_4.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/public/Img/Home/services/Blockchain_Development_Services.png");
import(/* webpackMode: "eager" */ "/vercel/path0/public/Img/Home/services/Decentralized_Exchange_Development.png");
import(/* webpackMode: "eager" */ "/vercel/path0/public/Img/Home/services/Metaverse_NFT_Launchpad_Development_Company.png");
import(/* webpackMode: "eager" */ "/vercel/path0/public/Img/Home/services/NFT_Development_Company.png");
import(/* webpackMode: "eager" */ "/vercel/path0/public/Img/Home/services/STO_Asset_Tokenization_Development_Company.png");
import(/* webpackMode: "eager" */ "/vercel/path0/public/Img/Home/services/tech_card_arrow.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/public/Img/Home/services/Token_Bound_Account_Development.png");
import(/* webpackMode: "eager" */ "/vercel/path0/public/Img/Home/services/Wallet_Development.png");
import(/* webpackMode: "eager" */ "/vercel/path0/public/Img/Home/services/Web3_Development_Services.png");
import(/* webpackMode: "eager" */ "/vercel/path0/public/Img/Home/skilledTech.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/public/Img/Home/StraightQuotes.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/public/Img/Home/TanThetaChart.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/public/Img/Home/TanThetaChartMobile.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/public/Img/Home/unmatchedTrans.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/public/Img/Home/Zapper.svg")