"use client";
import React from "react";

import ProjectHour from "./ProjectHour.jsx";
import ProjectTechnology from "./ProjectTechnology.jsx";
const Project = () => {
    return (
        <>
            <h2 className="text-center font-bold text-4xl mt-16">
                Our Costing Plans
            </h2>
            <div className="container mx-auto md:mt-24 ">
                <div className=" lg:grid lg:grid-cols-12 lg:gap-5 md:px-5 ">
                    <ProjectTechnology />

                    <div className="lg:col-span-8 grid lg:grid-rows-2 gap-y-5">
                        <ProjectHour />
                    </div>
                </div>
            </div>
        </>
    );
};

export default Project;
