"use client";

import React, { useState, useEffect } from "react";
import Image from "next/image";
import { cx } from "class-variance-authority";
import { IoClose } from "react-icons/io5";
import PhoneInput from "react-phone-input-2";
import toast, { Toaster } from "react-hot-toast";

import Rectangle from "../../../../public/Img/Home/Rectangle.png";
import beforeYouavtar from "../../../../public/Img/Home/beforeYouavtar.png";
import beforeYouaLeavebg from "../../../../public/Img/Home/beforeYouaLeavebg.png";
import checkMark from "../../../../public/Img/Home/checkMark.svg";
import { useRouter } from "next/navigation";

const BeforeYouLeave = ({ contactInfoTags }) => {
    const router = useRouter();

    const checkMarkData = [
        "Bespoke Project Blueprint",
        "Budget-Conscious Pricing",
        "Predicted Rollout Schedule",
        "Smooth Co-operation",
        "Scalable Solutions",
    ];

    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const [hasPopupShownThisSession, setHasPopupShownThisSession] =
        useState(false);
    const [submitStatus, setSubmitStatus] = useState("PENDING");

    // current tags
    const [currTags, setCurrTags] = useState({
        contactNumber: contactInfoTags.contactNumber[234],
        social: contactInfoTags.social[0],
    });

    const [phoneNumber, setPhoneNumber] = useState("");

    const changePhone = (value) => {
        // const input = e.target.value
        setContactInfo((prevContactInfo) => ({
            ...prevContactInfo,
            contactInfo: {
                ...prevContactInfo.contactInfo,
                contactNumber: value,
            },
        }));
        // setPhoneValidation(validatePhoneNumber(value))
    };

    // contact info changer
    const changeContactInfo = async (key, value) => {
        setContactInfo({
            ...contactInfo,
            [key]: value,
        });
    };

    // contact info
    const [contactInfo, setContactInfo] = useState({
        name: "",
        email: "",
        projectDescription: "",
        contactInfo: {
            contactNumberTag: contactInfoTags.contactNumber[0].id,
            contactNumber: "",
        },
    });

    // Math Captcha States
    const [num1] = useState(Math.floor(Math.random() * 10));
    const [num2] = useState(Math.floor(Math.random() * 10));
    const [captchaInput, setCaptchaInput] = useState("");
    const [captchaMessage, setCaptchaMessage] = useState(false);

    useEffect(() => {
        const handleMouseLeave = (event) => {
            if (!hasPopupShownThisSession && event.clientY <= 0) {
                setIsPopupOpen(true);
                setTimeout(() => setIsVisible(true), 50);
                setHasPopupShownThisSession(true);
            }
        };

        document.addEventListener("mouseleave", handleMouseLeave);

        return () => {
            document.removeEventListener("mouseleave", handleMouseLeave);
        };
    }, [hasPopupShownThisSession]);

    const closePopup = () => {
        setIsVisible(false);
        setTimeout(() => {
            setIsPopupOpen(false);
        }, 300);
    };

    // common input styles
    const BASE_INPUT = cx(
        "text-[#A1A6B6] bg-white placeholder:text-[#A1A6B6] font-manrope font-normal rounded-lg focus:outline-none py-3 px-4 shadow-contactShadow text-base"
    );

    const validateInputs = () => {
        let isValid = true;

        // Check if all fields are empty
        const allFieldsEmpty =
            !contactInfo.name.trim() &&
            !contactInfo.email.trim() &
                !(
                    contactInfo.contactInfo.contactNumber.trim() ||
                    phoneNumber.trim()
                ) &&
            !contactInfo.projectDescription.trim();

        if (allFieldsEmpty) {
            toast.error("Please fill out the form correctly.");
            return false;
        }

        // Validate individual fields
        if (!contactInfo.name.trim()) {
            toast.error("Name is required.");
            isValid = false;
        }

        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(contactInfo.email)) {
            toast.error("Invalid email address.");
            isValid = false;
        }

        // Validate phone number
        if (
            !(
                contactInfo.contactInfo.contactNumber.trim() ||
                phoneNumber.trim()
            ) &&
            phoneNumber.length < 10
        ) {
            toast.error("Invalid phone number.");
            isValid = false;
        }

        if (!contactInfo.projectDescription.trim()) {
            toast.error("Project description is required.");
            isValid = false;
        }

        return isValid;
    };

    const submitContactInfo = async () => {
        setSubmitStatus("SUBMITTING");

        if (!validateInputs()) {
            setSubmitStatus("PENDING");
            return false;
        }
        if (!captchaInput.trim()) {
            toast.error("CAPTCHA is required.");
            setSubmitStatus("PENDING");

            return;
        }
        if (parseInt(captchaInput) !== num1 + num2) {
            toast.error("Invalid CAPTCHA.");
            setSubmitStatus("PENDING");

            return;
        }

        try {
            // submitting form
            const submittingForm = await fetch(
                `${process.env.NEXT_PUBLIC_STRAPI_URL}/api/contacts`,
                {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${process.env.NEXT_PUBLIC_SUPER_TOKEN}`,
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        data: {
                            ...contactInfo,
                        },
                    }),
                }
            );
            const submittingFormData = await submittingForm.json();
            setPhoneNumber("");
            if (submittingFormData.data === null) {
                setSubmitStatus("ERROR");
                return false;
            } else {
                setSubmitStatus("SUCCESS");
                router.push("/thank-you-for-your-consultation");
                return true;
            }
        } catch (err) {
            console.log(err);
            return false;
        }
    };

    return (
        <>
            <div className="relative">
                {isPopupOpen && (
                    <>
                        <div
                            className={`fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-[999999] transition-opacity duration-300 ${
                                isVisible ? "opacity-100" : "opacity-0"
                            }`}
                            onClick={closePopup}
                        >
                            {/* modal  */}
                            <div
                                className={`bg-white shadow-lg w-auto text-center transform transition-all duration-300 rounded-[20px] overflow-hidden select-none ${
                                    isVisible ? "scale-100" : "scale-90"
                                }`}
                                onClick={(e) => e.stopPropagation()}
                            >
                                {/* modal content */}
                                <div className="flex lg:flex-row flex-col lg:gap-0 gap-5 rounded-[20px] overflow-hidden w-full">
                                    {/* left side */}
                                    <div
                                        className="relative w-full overflow-hidden flex items-center justify-center"
                                        style={{
                                            background: `linear-gradient(180deg, rgba(65, 14, 173, 0.082) 0%, rgba(65, 14, 173, 0.2) 100%), url(/Img/Home/beforeYouaLeavebg.png) no-repeat center center`,
                                            backgroundSize: "cover",
                                        }}
                                    >
                                        {/* closePopup button */}
                                        <span
                                            className="block lg:hidden absolute right-3 top-3 cursor-pointer"
                                            onClick={closePopup}
                                        >
                                            <IoClose className="text-[30px] text-[#A1A6B6]" />
                                        </span>

                                        {/* Rectangle */}
                                        <div className="absolute xl:left-0 lg:-left-1.5 md:-left-2 -left-3 xl:top-0 lg:-top-3 md:-top-4 -top-5">
                                            <Image
                                                src={Rectangle}
                                                alt="Rectangle"
                                                width={23}
                                                height={20}
                                            />
                                        </div>

                                        {/* middle content */}
                                        <div className="lg:mx-[55px] mx-5 lg:-mt-[85px] mt-6 mb-3 lg:mb-0">
                                            {/* First Box */}
                                            <div className="bg-white rounded-2xl lg:w-4/5 w-fit">
                                                <div className="w-full flex justify-start items-center">
                                                    <span className="my-4 mx-4 font-mono font-bold lg:text-3xl md:text-2xl text-xl  text-black text-start">
                                                        Wait! Before You Go...
                                                    </span>
                                                </div>
                                            </div>

                                            {/* Second Box */}
                                            <div className="bg-white lg:w-full w-fit px-4 py-2 rounded-t-2xl rounded-bl-2xl -mt-3 ml-10">
                                                <div className="my-2 flex flex-col justify-start items-start lg:gap-3 gap-2 w-fit">
                                                    <p className="font-inter font-bold text-lg leading-5 text-black text-start">
                                                        Get Free Expert Advice,
                                                        Just Ask Us!
                                                    </p>
                                                    <div className="flex flex-col justify-start items-start">
                                                        {checkMarkData?.map(
                                                            (check, index) => {
                                                                return (
                                                                    <p
                                                                        key={
                                                                            index
                                                                        }
                                                                        className="flex justify-start items-center lg:gap-3.5 gap-2"
                                                                    >
                                                                        <Image
                                                                            src={
                                                                                checkMark
                                                                            }
                                                                            alt="checkMarks"
                                                                            width={
                                                                                20
                                                                            }
                                                                            height={
                                                                                20
                                                                            }
                                                                            className="lg:w-[19px] lg:h-[19px] w-[15px] h-[15px]"
                                                                        />
                                                                        <span className="font-inter font-medium lg:text-base text-sm lg:leading-7 leading-5 tracking-wider text-[#323232]">
                                                                            {
                                                                                check
                                                                            }
                                                                        </span>
                                                                    </p>
                                                                );
                                                            }
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* beforeYou avtar */}
                                        <div className="absolute md:right-8 right-3.5 md:bottom-7 bottom-3">
                                            <Image
                                                src={beforeYouavtar}
                                                alt="beforeYouavtar"
                                                width={100}
                                                height={100}
                                                className="lg:w-[100px] lg:h-[100px] w-16 h-16"
                                            />
                                        </div>
                                    </div>

                                    {/* right side */}
                                    <div className="2xl:my-11 lg:my-9 w-full relative">
                                        <span
                                            className="lg:block hidden absolute right-3 2xl:-top-10 -top-8 cursor-pointer"
                                            onClick={closePopup}
                                        >
                                            <IoClose className="text-[30px] text-[#A1A6B6]" />
                                        </span>

                                        <div className="mx-8 flex flex-col md:gap-4 gap-2.5 font-mon text-[#A1A6B6] text-lg leading-5 font-medium">
                                            <div className="flex flex-col sm:flex-row lg:flex-col md:gap-4 gap-2.5 w-full">
                                                <input
                                                    autoComplete="off"
                                                    placeholder="Name"
                                                    className={cx(
                                                        BASE_INPUT,
                                                        "w-full"
                                                    )}
                                                    type="text"
                                                    id="name"
                                                    name="name"
                                                    value={contactInfo.name}
                                                    onChange={(e) =>
                                                        changeContactInfo(
                                                            "name",
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                                <input
                                                    autoComplete="off"
                                                    placeholder="Email"
                                                    className={cx(
                                                        BASE_INPUT,
                                                        "w-full"
                                                    )}
                                                    type="email"
                                                    id="email"
                                                    name="email"
                                                    value={contactInfo.email}
                                                    onChange={(e) =>
                                                        changeContactInfo(
                                                            "email",
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                            </div>
                                            <input
                                                type="text"
                                                placeholder="Describe Your Project/Idea In Brief"
                                                className={cx(BASE_INPUT)}
                                                id="projectDescription"
                                                name="projectDescription"
                                                value={
                                                    contactInfo.projectDescription
                                                }
                                                onChange={(e) =>
                                                    changeContactInfo(
                                                        "projectDescription",
                                                        e.target.value
                                                    )
                                                }
                                            />
                                            {/* <input
                                        type="text"
                                        placeholder="Contact Number"
                                        className={cx(BASE_INPUT)}
                                    /> */}

                                            <div
                                                className={cx(
                                                    BASE_INPUT,
                                                    "flex flex-row w-full !pl-0"
                                                )}
                                            >
                                                <div className="dropdown-left-border"></div>

                                                <PhoneInput
                                                    type="text"
                                                    country={"us"}
                                                    value={phoneNumber}
                                                    onChange={changePhone}
                                                    inputProps={{
                                                        required: true,
                                                    }}
                                                    placeholder=""
                                                    // countryCodeEditable={false}
                                                />
                                            </div>

                                            {/* Math Captcha */}
                                            <div className="flex items-center gap-3">
                                                <span className="text-black text-lg font-bold">
                                                    {num1} + {num2} =
                                                </span>
                                                <input
                                                    type="text"
                                                    className={cx(
                                                        BASE_INPUT,
                                                        "w-24"
                                                    )}
                                                    onChange={(e) =>
                                                        setCaptchaInput(
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                                {captchaMessage && (
                                                    <p className="text-sm text-red-500">
                                                        {captchaMessage}
                                                    </p>
                                                )}
                                            </div>

                                            <button
                                                onClick={() =>
                                                    submitContactInfo()
                                                }
                                                className={cx(
                                                    "btn-primary-purple !bg-[#410EAD] !text-white !py-3 !px-10 !tracking-wide text-xl 2xl:mt-5 lg:mt-4 mb-5 lg:mb-0"
                                                )}
                                            >
                                                {submitStatus ===
                                                "SUBMITTING" ? (
                                                    <div
                                                        className="inline-block h-5 w-5 animate-spin rounded-full border-2 border-solid border-white border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                                                        role="status"
                                                    >
                                                        <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                                                            Loader
                                                        </span>
                                                    </div>
                                                ) : (
                                                    "Submit Now"
                                                )}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Toaster
                                position="top-center"
                                reverseOrder={false}
                            />
                        </div>
                    </>
                )}
            </div>
        </>
    );
};

export default BeforeYouLeave;
