"use client";

import React, { useState } from "react";
import { cx } from "class-variance-authority";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import "../../../../app/globals.css";

export const dynamic = "force-dynamic";

const HomeFaqs = () => {
    const [isOpen, setIsOpen] = useState(0);
    const faqShadow = {
        boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
    };

    const renderers = {
        a: ({ href, children }) => (
            <a href={href} className="custom-link">
                {children}
            </a>
        ),
    };

    const faqData = [
        {
            id: 1,
            question:
                "How do you collaborate with clients on custom development projects?",
            answer: "We take a collaborative approach, working closely with you to understand your business needs. Whether it's building a blockchain solution or integrating AI, we ensure the final product aligns perfectly with your goals.",
        },
        {
            id: 2,
            question:
                "Do you offer consulting services for blockchain and Web3 projects?",
            answer: "Yes, we provide expert blockchain and Web3 consulting to help you choose the right technology stack and guide you through the development process, ensuring your project is future-proof.",
        },
        {
            id: 3,
            question: "Can you build custom blockchain solutions?",
            answer: "Absolutely! We specialize in custom blockchain development, creating secure, scalable applications that fit your specific needs, from decentralized apps (dApps) to smart contracts.",
        },
        {
            id: 4,
            question: "What services do you offer for the Metaverse and NFTs?",
            answer: "We create immersive Metaverse experiences and build robust NFT platforms. Whether you're launching a new NFT collection or designing a virtual world, we ensure seamless integration with blockchain technology.",
        },
        {
            id: 5,
            question: "Which blockchain technologies do you work with?",
            answer: "We work with leading blockchain platforms like Ethereum and Solana to deliver decentralized applications that are secure, scalable, and tailored to your business needs.",
        },
        {
            id: 6,
            question:
                "How do AI and blockchain work together in your solutions?",
            answer: "We combine the power of AI and blockchain to automate processes, enhance data analysis, and create smarter decentralized applications. This integration brings greater efficiency and security to your project.",
        },
        {
            id: 7,
            question: "Do you offer AI development services?",
            answer: "Yes, we offer a full range of AI development services, including AI agent development for various use cases, generative AI solutions, AI chatbot development, adaptive AI systems, AI integration, and AI consulting services. ",
        },
        {
            id: 8,
            question:
                "What security measures do you take for blockchain and AI projects?",
            answer: "Security is our top priority. We ensure your blockchain and AI systems are protected with encryption, regular audits, and stringent testing so your solutions remain secure and reliable.",
        },
        {
            id: 9,
            question: "Where is your company based?",
            answer: "Our team operates globally, with a presence in India, the United States, Canada, the UAE, and the United Kingdom. We provide remote services to clients worldwide, ensuring smooth communication and support wherever you're located.",
        },
        {
            id: 10,
            question: "Do you offer post-launch support and maintenance?",
            answer: "Yes, we provide ongoing support and maintenance for blockchain, Web3, AI, and NFT projects, ensuring your solutions stay up-to-date with the latest technologies and perform optimally.",
        },
        {
            id: 11,
            question:
                "Which industries do you serve with your blockchain and AI solutions?",
            answer: "We work with industries such as finance, healthcare, e-commerce, gaming, and real estate, helping businesses integrate cutting-edge blockchain and AI technologies to stay ahead of the competition.",
        },
        {
            id: 12,
            question:
                "How long does it take to develop a blockchain or AI solution?",
            answer: "Development time depends on the complexity of the project. On average, blockchain and Web3 solutions take 3-6 months, while AI projects usually take around 2-5 months.",
        },
    ];

    return (
        <div>
            <div className="container mx-auto mt-16 md:mt-[140px] px-4 md:px-24 lg:px-24 xl:px-0">
                <div className="w-full text-center">
                    <h2
                        className={`text-2xl lg:text-4xl !leading-snug font-bold font-manrope text-black flex justify-center items-center`}
                    >
                        Frequently asked questions
                    </h2>
                </div>
            </div>

            <div className="container px-4 mx-auto my-20 mt-10 md:px-24 lg:px-24 xl:px-0 ">
                {faqData?.map((item) => (
                    <div className="my-4" key={item?.id}>
                        <div
                            style={faqShadow}
                            onClick={() =>
                                setIsOpen(item?.id === isOpen ? 0 : item?.id)
                            }
                            className="rounded-2xl w-full md:max-w-7xl md:mx-auto bg-white"
                        >
                            <div className="flex items-center justify-start gap-5 p-4 text-base font-semibold cursor-pointer">
                                {isOpen === item?.id ? " - " : " + "}
                                <h3>{item?.question}</h3>
                            </div>
                        </div>
                        <div
                            className={cx(
                                isOpen === item?.id ? "h-auto" : "hidden h-0",
                                "w-full md:max-w-7xl md:mx-auto p-4 transition-[height] ease-in-out duration-500"
                            )}
                        >
                            <ReactMarkdown
                                remarkPlugins={[remarkGfm]}
                                components={renderers}
                            >
                                {item?.answer}
                            </ReactMarkdown>

                            {item?.keyPoints && (
                                <span>
                                    {item?.keyPoints?.map((points, index) => {
                                        return (
                                            <>
                                                <li
                                                    key={index}
                                                    className="list-disc"
                                                >
                                                    <span>{points?.point}</span>
                                                    <br />
                                                </li>
                                            </>
                                        );
                                    })}
                                </span>
                            )}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default HomeFaqs;
